<template>
  <div class="container">
    <!-- 顶部按钮 -->
    <div class="headTitle">
      <van-nav-bar  :title="hoteltype==0?'酒店':'民宿'" :border='false'>
        <template #left>
          <van-icon :size="24" @click="$router.push('/')" color="#141212" name="arrow-left" slot="left" />
        </template>
      </van-nav-bar>
    </div>
    <div class="hotels">
      <div class="list" v-if="hotels.length>0">
        <div class="hotel" v-for="(item, index) in hotels" :key="index">
          <div class="pic" :style='{ background:"url("+ item.cover +") no-repeat center center/cover"}' @click.stop="goInner(item,index)">
            <a href="javascript:;" :class="{'follow':item.collectId}" @click.stop="collect(item)">取消收藏</a>
          </div>
          <div class="info">
            <a href="javascript:;" @click="goInner(item,index)">
              <h1>{{item.hotelName}}</h1>
              <address><img src="./img/icon-address.png" alt="地址" />{{item.address}}</address>
              <div class="distance" v-if="item.distance">距您{{item.distance}}公里</div>
              <div class="price">￥{{item.minPirce ? item.minPirce/100 : '0'}}起/晚</div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="t_a no_list">
        <img src="./img/index_no_list.png" alt="">
        <div>暂无内容！</div>
      </div>
      <div class="loading" v-show="loading"><img src="./img/loading.png" alt="正在加载" />正在加载</div>
    </div>
  </div>
</template>
<script>

import { NavBar,Icon, Swipe, SwipeItem, Image, Toast } from 'vant';
import apis from './utils/apis';
export default {
  name: 'hotelMore',
  components: {
      'van-icon': Icon,
      'van-swipe': Swipe,
      'van-swipe-item': SwipeItem,
      'van-image': Image,
    'van-nav-bar': NavBar,
  },
  data () {
    return {
      showMenu: false, // 顶部隐藏菜单显示/隐藏
      hoteltype: 0, // 酒店/民宿
      pageIndex: 1,
      isLoadMore: true,
      isShowLoginPop: false,
      hotels: [],
      loading: false, // 列表加载中
    }
  },
  watch: {
    hoteltype(n, o) {
        if (n == 0) {
            this.placeName = '酒店';
        } else {
            this.placeName = '民宿';
        }
    },
  },
  methods: {
    //获取列表
        searchHotelList() {
            if (!this.isLoadMore) return;
            this.$get({
                url: apis.searchHotelList,
                params: {
                    isRecommend: null, //是否推荐
                    areaName: '',
                    sortName: 'sequence',
                    sortOrder: 'asc',
                    token: this.$global.token,
                    pageSize: 10,
                    pageIndex: this.pageIndex,
                    typeCode: this.hoteltype == 0 ? 'jd' : 'mins',
                },
            }).then(rsp => {
                if (rsp.data && rsp.data.length) {
                    this.hotels = this.hotels.concat(rsp.data);
                    this.loading = false;
                    if (rsp.data.length === 10) {
                        this.pageIndex = this.pageIndex + 1;
                    } else {
                        this.isLoadMore = false;
                    }
                } else {
                    this.isLoadMore = false;
                }
            });
        },
        // 搜索酒店
        search() {
            if (this.searchkey) {
                sessionStorage.setItem('searchkey', this.searchkey);
            }
            sessionStorage.setItem(
                'hoteltype',
                this.hoteltype == 0 ? 'jd' : 'mins'
            );
            this.$router.push({
                path: '/hotelList',
            });
        },
            
     // 顶部后退done
        back() {
            this.revert();
        },
        // 顶部隐藏菜单done
        menu() {
            this.showMenu = !this.showMenu;
        },
    //返回首页done
        revert(num) {
            this.$router.go(-1);
        },
    //跳转订单列表
        userCenter() {
            if (!this.$global.token) return (this.isShowLoginPop = true);
            sessionStorage.setItem('orderList', 1);
            this.$router.push({
                path: 'orderList',
                query: {
                    orderType: 1,
                },
            });
        },
        //跳转酒店内页done
        goInner(row, index) {
            this.$router.push({
                path: '/hotelInner',
                query: {
                    sellerId: row.sellerId,
                },
            });
        },
        //收藏事件响应事件
        collect(row) {
            if (!this.$global.token) return (this.isShowLoginPop = true);
            row.collectId ? this.cancelCollect(row) : this.addCollect(row);
        },
        addCollect(row) {
            //添加收藏
            const me = this;
            me.$post({
                url: apis.addCollect,
                params: {
                    token: me.$global.token,
                    bizType: 3,
                    bizId: row.sellerId,
                },
            }).then(rsp => {
                // Toast('操作成功');
                row.collectId = 1;
            });
        },
        cancelCollect(row) {
            //取消收藏
            const me = this;
            me.$post({
                url: apis.cancelCollect,
                params: {
                    token: me.$global.token,
                    bizType: 3,
                    bizId: row.sellerId,
                },
            }).then(rsp => {
                // Toast('操作成功');
                row.collectId = null;
            });
        },
  },
  created () {
    this.hoteltype = this.$route.query.hoteltype
    this.searchHotelList()
  },
  mounted () {
  },
}
</script>
<style scoped src="./index.css"/>
<style scoped >

.headTitle {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 999;
}
.container {
  width: 100%;
  padding-top: 10px;
  overflow-x: hidden;
  height: 100vh;
}

</style>
